<template>
  <div>
    <h1>Hey {{user.name}}👋</h1>
    <p>You can do it 💪</p>
    <p>For now just click the links on the left</p>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    return {
      user: computed(() => store.state.user.activeUser),
    };
  },
};
</script>
<style lang="scss" scoped>
  h1 {
    font-size: size(72);
    margin: size(30) 0;
  }
</style>
